<template>
    <div>
        <ion-app>
            <!--<side-menu v-if="!isLogin"/>-->
            <bot-navigation v-if="!isLogin">
            </bot-navigation>
            <ion-router-outlet v-if="isLogin"/>
        </ion-app>
    </div>
</template>

<script>
import {IonApp} from '@ionic/vue';
import {defineComponent} from 'vue';
// import SideMenu from './components/base/SideMenu.vue';
import BotNavigation from './components/base/BotNavigation.vue';
import {IonRouterOutlet} from '@ionic/vue';
import FCMservice from './/services/fcm';
import {getPlatforms} from '@ionic/vue';

export default defineComponent({
    name: 'App',
    components: {
        IonApp,
        BotNavigation,
        // SideMenu,
        IonRouterOutlet,
    },
    mounted() {
        // if Platform is iOS, ask for permission to send Push Notifications, but only if not in browser or desktop
        if (getPlatforms().includes('ios') && !getPlatforms().includes('mobileweb') && !getPlatforms().includes('desktop')) {
            FCMservice.getPermission();
        }
    },
    computed: {
        isLogin() {
            return this.$route.name === 'Login' || this.$route.name === 'SetPassword';
        },
        allAccepted() {
            return true;
            // return this.$store.getters.allDocumentsAccepted;
        },
    },
});
</script>
